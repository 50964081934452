html,
body {
  height: 100%;
}
html {
  font-size: calc(10pt + 0.75vw);
}
body {
  background-color: #141414;
  color: #ebebeb;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  transition: background-color 250ms ease;
}
body main {
  height: 100%;
  display: grid;
  grid-template: 1fr/1fr;
  align-items: flex-end;
}
body main h1 {
  margin: 0 8px;
  font-size: 4em;
  line-height: 0.73em;
}
body main nav ul {
  margin: 0;
  padding: 16px;
  list-style-type: none;
  backdrop-filter: invert(1);
}
body main nav ul li {
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
  text-align: right;
  font-size: 3em;
  display: inline-block;
}
body main nav ul li a {
  color: inherit;
  text-decoration: none;
  background-color: #141414;
  padding: 0 8px;
}
body main nav ul li a:hover {
  filter: invert(1);
}
